import { MoonIcon, SunIcon } from '@heroicons/react/20/solid'
import * as Switch from '@radix-ui/react-switch'
import { useFetcher } from '@remix-run/react'
import * as React from 'react'
import { useDebounce } from '#app/utils/misc'
import { useOptionalUser } from '#app/utils/user'
import { css } from '#styled-system/css/css.js'

export const CustomizeColorScheme = () => {
	const user = useOptionalUser()
	const fetcher = useFetcher()

	const [colorScheme, setColorScheme] = React.useState(() =>
		user ? user.colorScheme : 'light',
	)

	const handleColorSchemeChange = (value: string) => {
		document.documentElement.className = value
	}

	const submitColorSchemeChange = useDebounce((value: string) => {
		if (!user) return

		fetcher.submit(
			{ colorScheme: value },
			{
				method: 'POST',
				action: '/resources/color-scheme',
			},
		)
	}, 2000)

	return (
		<>
			<Switch.Root
				onCheckedChange={checked => {
					setColorScheme(checked ? 'dark' : 'light')
					handleColorSchemeChange(checked ? 'dark' : 'light')
					submitColorSchemeChange(checked ? 'dark' : 'light')
				}}
				checked={colorScheme === 'dark'}
				aria-label="Toggle dark mode"
				className={css({
					cursor: 'pointer',
					display: 'inline-flex',
					height: '38px' as any,
					width: '38px' as any,
					flexShrink: '0',
					alignItems: 'center',
					justifyContent: 'center',
					borderRadius: '8px',
					borderWidth: '1px',
					borderColor: 'gray7',
					backgroundColor: 'gray3',
					'&:hover': {
						borderColor: 'gray8',
						backgroundColor: 'gray4',
					},
				})}
			>
				<Switch.Thumb>
					{colorScheme === 'dark' ? (
						<SunIcon
							className={css({
								height: '20px',
								width: '20px',
								color: 'gray11',
							})}
						/>
					) : (
						<MoonIcon
							className={css({
								height: '20px',
								width: '20px',
								color: 'gray11',
							})}
						/>
					)}
				</Switch.Thumb>
			</Switch.Root>
		</>
	)
}
